
import React from "react";

/*import Index from "./BuerscheFalkenApotheke/BuerscheFalkenApotheke";
import Impressum from "./BuerscheFalkenApotheke/Impressum"
import Datenschutz from "./BuerscheFalkenApotheke/Datenschutz"*/

import Index from "./DomApotheke/DomApotheke";
import Impressum from "./DomApotheke/Impressum"
import Datenschutz from "./DomApotheke/Datenschutz"

/*import Index from "./SchreinersApotheken/SchreinersApotheken";
import Impressum from "./SchreinersApotheken/Impressum"
import Datenschutz from "./SchreinersApotheken/Datenschutz"*/

import { Router } from "@reach/router"
import Helmet  from "react-helmet"

export default () => (
    <>
        <Helmet>

        </Helmet>
        <Router basepath="/">
            <Impressum path="/Impressum" />
            <Datenschutz path="/Datenschutz" />
            <Index path="/" />
        </Router>
    </>
);
